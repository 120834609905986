// a
export const APP_NAME = "Xtream Cloud For Resellers";
export const API_HOST = process.env.API_HOST;
export const WEB_HOST = process.env.WEB_HOST || "https://dev.xtream.cloud";
export const PANEL_HOST = process.env.PANEL_HOST;

/////////**PACKAGE**////////////////
export const PACKAGE_PRICE = process.env.REACT_APP_PACKAGE_PRICE || 12;
export const PACKAGE_PRICE_BR = process.env.REACT_APP_PACKAGE_PRICE_BR || 64;
export const PACKAGE_PRICE_SALE =
  process.env.REACT_APP_PACKAGE_PRICE_SALE || 30;
// export const AMERIA_PRODUCT_ID = process.env.REACT_APP_AMERIA_PRODUCT_ID || 14;
/////////**PACKAGE**////////////////

export const ANDROID_TV_APK = "https://files.xtream.cloud/x-cloud.apk";

export const ANDROID_TV_MOBILE_APK =
  "https://files.x-players.com/x-cloud/mobile.apk";
export const ANDROID_TV =
  "https://play.google.com/store/apps/details?id=com.xcloud.app&hl=en&gl=US";
export const ANDROID_MOBILE =
  "https://play.google.com/store/apps/details?id=com.xcloud.app";
export const IOS = "https://apps.apple.com/app/xcloud-mobile/id6471106231";
export const ROKU = "https://my.roku.com/account/add/JKZTPR2";
export const SAMSUNG_STORE =
  "https://www.samsung.com/us/tvs/smart-tv/samsung-tv-plus-and-smart-hub/";
export const LG_STORE =
  "https://au.lgappstv.com/main/tvapp/detail?appId=1199750&catCode1=&moreYn=N&cateYn=N&orderType=0&headerName=&appRankCode=&sellrUsrNo=";

export const NOTIFY_HOST = "https://notify.inorain.com/xcloud/";
export const AMERIA_PAYMENT =
  "https://pay.inorain.com/api/v2/ameria/payment_link";
// b
// c
export const CURRENCY = "USD";
// d
export const DEVICE_PRICE = 8;
export const DOMAIN = "xtream.cloud";
// e
// f
// g
// h
// i
// j
// k
// l
// m
// n
// o
// p
export const PAYPAL_CLIENT_ID =
  "ASkBwxo3uwegf2yNsHzZqgnzTUp-C6e9BKr8-Fa3TM5p7ZhSVzKJpglqWzMJS4zDq6I4xAqaeyFkoFTJ";
// q
// r
// s
export const SUPPORT = "support@xtream.cloud";
// t
export const TELEGRAM = "https://t.me/xtreamcloud";
// u
// v
export const VERSION = "1.2.1";
// w
// x
// y
// z
